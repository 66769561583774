import { CostOptimizationRecommendationType } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { formatString } from './number'

interface MergedColumns {
  id: string
  parser: (value: any) => string
}

interface AdditionalColumn {
  key: string
  headerKey?: string
  parser?: (value: any) => string
}

interface WarningColumn {
  key: string
  parser?: (value: any) => string
}

const additionalColumns: Record<CostOptimizationRecommendationType, AdditionalColumn[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [
    { key: 'dbEngine' },
    { key: 'dbEngineMajorVersion' },
    { key: 'estimatedDbVcpu', parser: (value) => formatString(value, { suffix: ' vCPU' }) },
  ],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [
    { key: 'estimatedGbMonths', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'totalEstimatedGbMonthsIntelligentTiering', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSAuroraGravitonInstances]: [{ key: 'currentInstanceType' }, { key: 'targetInstanceType' }],
  [CostOptimizationRecommendationType.RDSReservedInstance]: [{ key: 'instanceType' }, { key: 'deploymentOption' }, { key: 'dbEngine' }],
  [CostOptimizationRecommendationType.ElastiCacheReservedInstance]: [{ key: 'cacheEngine' }],
  [CostOptimizationRecommendationType.OpenSearchReservedInstance]: [
    { key: 'instanceType', headerKey: 'instanceTypeToReserve' },
    { key: 'averageNumberOfNodes', parser: (value) => Math.round(value).toString() },
  ],
  [CostOptimizationRecommendationType.ElastiCacheRedisToValkey]: [],
}

export function getAdditionalColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return additionalColumns[costOptimizationRecommendationType] || []
}

const warningAdditionalColumns: Record<CostOptimizationRecommendationType, WarningColumn[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [],
  [CostOptimizationRecommendationType.ElastiCacheReservedInstance]: [],
  [CostOptimizationRecommendationType.OpenSearchReservedInstance]: [],
  [CostOptimizationRecommendationType.RDSAuroraGravitonInstances]: [
    {
      key: 'riArns',
      parser: (value) =>
        value
          ?.split(',')
          .map((v: string) => `- ${v}`)
          .join('\n'),
    },
  ],
  [CostOptimizationRecommendationType.RDSReservedInstance]: [],
  [CostOptimizationRecommendationType.ElastiCacheRedisToValkey]: [],
}

export function getWarningColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return warningAdditionalColumns[costOptimizationRecommendationType] || []
}

const mergedAdditionalColumns: Record<CostOptimizationRecommendationType, MergedColumns[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [],
  [CostOptimizationRecommendationType.RDSAuroraGravitonInstances]: [],
  [CostOptimizationRecommendationType.RDSReservedInstance]: [
    {
      id: 'instanceToReserve',
      parser: (row) => {
        return `${Math.round(row.additionalFields?.numberOfInstancesTypeToReserve)}x ${row.additionalFields?.instanceTypeToReserve} Single-AZ`
      },
    },
  ],
  [CostOptimizationRecommendationType.ElastiCacheReservedInstance]: [
    {
      id: 'instanceToReserve',
      parser: (row) => {
        return `${Math.round(row.additionalFields?.numberOfInstancesTypeToReserve)}x ${row.additionalFields?.instanceTypeToReserve}`
      },
    },
  ],
  [CostOptimizationRecommendationType.OpenSearchReservedInstance]: [],
  [CostOptimizationRecommendationType.ElastiCacheRedisToValkey]: [],
}

export function getMergedColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return mergedAdditionalColumns[costOptimizationRecommendationType] || []
}
