import { ActionIcon, Box, Flex, Text, Title, Tooltip } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { RecommendationStatus, RecommendationType } from 'api/domain/entities/recommendation'
import { CostOptimizationResource } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { useGetRecommendation } from 'api/query/recommendation'
import { useGetTenantMe } from 'api/query/tenant'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  getAdditionalColumnsByRecommendationType,
  getMergedColumnsByRecommendationType,
  getWarningColumnsByRecommendationType,
} from 'utils/column'
import { formaPercentage, formatCost } from 'utils/number'

export function CostOptimizationRecommendation() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenantMe()
  const { data: recommendation, isLoading: isLoadingRecommendation } = useGetRecommendation(
    RecommendationType.COST_OPTIMIZATION,
    tenant?.id,
    id,
  )
  const resources = useMemo(() => {
    if (!recommendation || !recommendation.resources) {
      return []
    }

    switch (searchParams.get('status')) {
      case RecommendationStatus.UNRESOLVED:
        return recommendation.unresolvedResources
      case RecommendationStatus.RESOLVED:
        return recommendation.resolvedResources
      case RecommendationStatus.OUTDATED:
        return recommendation.outdatedResources
      default:
        return recommendation.resources
    }
  }, [recommendation, searchParams])

  const additionalColumns = useMemo(() => {
    const columns: ColumnDef<CostOptimizationResource>[] = []
    if (!recommendation || !recommendation.resources || !recommendation.resources[0]) {
      return columns
    }

    const costOptimizationType = recommendation.costOptimizationType
    const additionalColumns = getAdditionalColumnsByRecommendationType(costOptimizationType)
    const warningColumns = getWarningColumnsByRecommendationType(costOptimizationType)
    const mergedColumns = getMergedColumnsByRecommendationType(costOptimizationType)

    for (const additionalColumn of additionalColumns) {
      if (columns.find((col) => col.id === additionalColumn.key)) {
        continue
      }

      columns.push({
        id: additionalColumn.key,
        accessorKey: `additionalFields.${additionalColumn.key}`,
        header: t(`cost:columns.additional-fields.${additionalColumn.headerKey || additionalColumn.key}`),
        cell: (col) => {
          if (additionalColumn.parser) {
            return <Text size="sm">{additionalColumn.parser(col.getValue())}</Text>
          }

          return <Text size="sm">{col.getValue() as string}</Text>
        },
      })
    }

    for (const mergedColumn of mergedColumns) {
      if (columns.find((col) => col.id === mergedColumn.id)) {
        continue
      }

      columns.push({
        id: mergedColumn.id,
        header: t(`cost:columns.additional-fields.${mergedColumn.id}`),
        cell: (cellContext) => {
          if (mergedColumn.parser) {
            return <Text size="sm">{mergedColumn.parser(cellContext.row.original)}</Text>
          }

          return <Text size="sm">{cellContext.getValue() as string}</Text>
        },
      })
    }

    for (const warningColumn of warningColumns) {
      if (columns.find((col) => col.id === warningColumn.key)) {
        continue
      }

      columns.push({
        id: warningColumn.key,
        accessorKey: `additionalFields.${warningColumn.key}`,
        header: t(`cost:columns.additional-fields.${warningColumn.key}`),
        cell: (col) => {
          let txt = col.getValue() as string
          if (!txt) {
            return <Text size="sm" />
          }
          if (warningColumn.parser) {
            txt = warningColumn.parser(txt)
          }

          return (
            <Tooltip label={t(`cost:warnings.${warningColumn.key}`).replace('%s', txt)}>
              <IconAlertTriangle />
            </Tooltip>
          )
        },
      })
    }

    return columns
  }, [recommendation, t])

  const columns: ColumnDef<CostOptimizationResource>[] = [
    {
      id: 'accountId',
      accessorKey: 'accountId',
      header: t('cost:columns.account-id'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'accountName',
      accessorKey: 'accountName',
      header: t('cost:columns.account-name'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'region',
      accessorKey: 'region',
      header: t('cost:columns.region'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'resourceId',
      accessorKey: 'resourceId',
      header: t('cost:columns.resource-id'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'resourceName',
      accessorKey: 'resourceName',
      header: t('cost:columns.resource'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'annualizedCost',
      accessorKey: 'annualizedCost',
      header: t('cost:columns.annualized-cost'),
      cell: (col) => {
        return <Text size="sm">{formatCost(col.getValue() as number)}</Text>
      },
    },
    {
      id: 'annualizedSavings',
      accessorKey: 'annualizedSavings',
      header: t('cost:columns.potential-savings'),
      cell: (col) => {
        return <Text size="sm">{formatCost(col.getValue() as number)}</Text>
      },
    },
    {
      id: 'savingPercentage',
      accessorKey: 'savingPercentage',
      header: t('cost:columns.saving-percentage'),
      cell: (col) => {
        return <Text size="sm">{formaPercentage(col.getValue() as number)}</Text>
      },
    },
    {
      id: 'realizedSavings',
      accessorKey: 'realizedSavings',
      header: t('cost:columns.realized-savings'),
      cell: (col) => {
        return <Text size="sm">{formatCost(col.getValue() as number)}</Text>
      },
    },
    ...additionalColumns,
  ]

  if (isLoadingTenant || isLoadingRecommendation) {
    return <Loading size="xl" />
  }

  return (
    <>
      <Title order={1}>{t('pages.cost-and-usage').toUpperCase()}</Title>

      <Flex align="center" mt={20}>
        <ActionIcon onClick={() => navigate('/cost-recommendations')} size="md" variant="transparent" color="gray">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H6M12 5l-7 7 7 7"></path>
          </svg>
        </ActionIcon>
        <Title ml={10} size="25px">
          {recommendation?.translatedTitle}
        </Title>
      </Flex>
      {recommendation?.description && (
        <Text ta="justify" maw={800} mt={20} size="16px" c="gray.7">
          {recommendation?.translatedDescription}
        </Text>
      )}
      <Box mt={26}>
        <Table
          columns={columns}
          data={resources || []}
          defaultSort={{
            id: 'annualizedSavings',
            desc: true,
          }}
        />
      </Box>
    </>
  )
}
